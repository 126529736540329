.home {
  .banner {
    @extend .cover;
    padding: 14rem 0 16rem;
    text-align: center;
    h1 {
      font-size: $hero-size;
      letter-spacing: -0.2rem;
    }
    h2 {
      text-transform: uppercase;
      font-size: $hero-sub-size;
      letter-spacing: 0.2rem;
    }
    .intro {
      margin-top: 2rem;
      margin-bottom: 3rem;
    }
    .row {
      @extend .justify-content-center;
    }
    .call-to-action a {
      p {
        display: inline;
      }
      strong {
        color: $primary;
        white-space: nowrap;
      }
    }
    .call-to-action a:hover strong {
      color: #fff;
    }
  }
  @include media-breakpoint-down(sm) {
    .banner {
      padding: 11rem 0 7rem;
      h1 {
        font-size: $hero-size * .8;
      }
    }
  }
  .services {
    padding: 4rem 0;
    background-color: $dark-gray;
    color: #ffffff;
    text-align: center;
    h2 {
      margin-bottom: 2em;
      font-size: $h1-font-size;
    }
    .service-row {
      @extend .list-unstyled;
      @extend .d-flex;
      @extend .justify-content-center;
      margin-bottom: 2em;
      .service-col {
        margin-bottom: 3em;
        button:hover {
          img {
            opacity: 0.4;
          }
          font-weight: bold;
          color: $primary;
        }
      }
      img {
        height: 84px;
        margin-bottom: 1rem;
      }
    }
    .call-to-action a {
      color: $dark-gray;
      background-color: $primary;
    }
    .call-to-action a:hover {
      color: #000;
      background-color: #ddd;
    }
    @include media-breakpoint-down(sm) {
      h2 {
        font-size: $h1-font-size*.8;
      }
      .service-row {
        img {
          height: 64px;
        }
      }
    }
  }
  .tag-line {
    padding: 8rem 0;
    text-align: center;
    h2 {
      font-size: $h1-font-size;
      max-width: 40rem;
      margin: 0 auto 3rem;
    }
    @include media-breakpoint-down(sm) {
      h2 {
        font-size: $h1-font-size*.8;
      }
      padding: 4rem 0;
    }
}
}

.service-modal {
  .service-icon,
  .service-image,
  .service-description {
    padding: 3rem;
  }
  .service-icon {
    text-align: center;
    padding-right: 0;
    img {
      height: 84px;
    }
  }
  .close-button {
    float: right;
    margin-top: -3rem;
    margin-right: -2rem;
    padding: 1rem;
  }
  .service-image {
    @extend .cover;
    min-height: 14rem;
  }
}