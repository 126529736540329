a {
  text-decoration: none;
}

.as-text {
  background-color: transparent;
  border-width: 0;
  color: inherit;
}
.call-to-action {
  @extend .d-flex;
  @extend .justify-content-center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  a {
    display: block;
    border-radius: 50rem;
    color:#fff;
    background-color: #000;
    padding: .8rem 2rem;
    font-weight: bold;
  }
  a:hover {
    color: #000;
    background-color: $primary;
  }
}

.loading {
  @extend .list-unstyled;
  @extend .d-flex;
  @extend .justify-content-center;
  @extend .align-items-center;
}

.cover {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}


label {
  display: block;
  text-transform: uppercase;
  color: $dark-gray;
}
textarea,
input[type=email],
input[type=text] {
  width: 100%;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  padding: .5rem 1rem;
}
textarea {
  height: 10rem;
}

.contact-form {
  .submit-button {
    text-align: right;
  }
}

.modal-header {
  h2,
  h3 { margin-bottom: 0; }
}