@import url('https://fonts.googleapis.com/css2?family=Bodoni+Moda:ital,wght@0,400;0,700;1,400&family=Roboto:ital,wght@0,400;0,700;1,400&display=swap');

@import "bootstrap/scss/_functions";
@import "bootstrap/scss/_variables";

/* bootstrap override goes here */
$primary: #E0b456;
$theme-colors: (
  "primary": $primary,
);
$headings-font-family: 'Bodoni Moda', serif;
$h1-font-size: 3rem;
$h2-font-size: 2rem;
$h3-font-size: 1.5rem;
@import "bootstrap/scss/bootstrap";

@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/fontawesome";

/* custom vars */
$dark-gray: #333333;
$hero-size: 4rem;
$hero-sub-size: 1.5rem;

@import "./common";
@import "./footer";
@import "./globalnav";
@import "./home";
@import "./spinner";
@import "./page";



