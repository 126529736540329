.footer {
  background-color: $dark-gray;
  color: #fff;
  padding: 4rem 0;
  font-size: .9rem;
  h3 {
    font-family: $font-family-base;
    font-weight: bold;
    font-size: inherit;
    color: $primary;
  }
  .legal {
    padding-top: 2.4rem;
    border-top: 1px solid #666;
    font-size: .75rem;
    strong {
      color: $primary;
    }
  }
  .social {
    @extend .list-unstyled;
    @extend .d-flex;
    @extend .justify-content-start;
    li {
      padding-right: 1rem;
      font-size: 2rem;
    }
    a {
      color: #fff;
    }
  }
  .logo {
    width: 8rem;
  }
  .footer-col {
    margin-bottom: 2rem;
  }
}
