.page {
  .banner {
    @extend .cover;
    padding: 16rem 0 10rem;
    color: #fff;
    text-align: center;
    .row {
      @extend .justify-content-center;
    }
    h1 {
      color: $primary;
      margin-bottom: 1rem;
    }
    h2 {
      font-family: $font-family-base;
      font-size: $h3-font-size*0.85;
    }
  }
  .service-row {
    @extend .d-flex;
    @extend .justify-content-center;
    @extend .align-content-center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .service-image {
      @extend .cover;
      height: 100%;
      border-top-right-radius: 2rem;
      border-bottom-left-radius: 2rem;
      min-height: 20rem;
    }
    > div {
      padding: 2rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 0;
      margin-bottom: 2rem;
      > div {
        padding: 2rem 2rem 0;
      }
    }
  }
  .service-description {
    text-align: left;      
  }
  .service-icon {
    text-align: right;      
  }
  .service-row:nth-child(even) {
    flex-direction: row-reverse;
    .service-icon {
      text-align: left;      
    }
    .service-description {
      text-align: right;      
    }
  }
  .slice-people {
    text-align: center;
    padding: 4rem 0;
    h3 {
      font-weight: bold;
    }
    .headshot {
      width: 14rem;
      max-width: 100%;
      display: block;
      border-radius: 50%;
      margin: 0 auto 2rem;
    }
    .people-entry {
      margin-bottom: 5em;
    }
  }
  .slice-contact {
    padding: 4rem;
    label {
      display: block;
      text-transform: uppercase;
      color: $dark-gray;
    }
    textarea,
    input[type=email],
    input[type=text] {
      width: 100%;
      margin-bottom: 1rem;
      border: 1px solid #ccc;
      padding: .5rem 1rem;
    }
    textarea {
      height: 10rem;
    }
    .message {
      text-align: center;
      margin-bottom: 4rem;
    }
    .submit-button {
      text-align: center;
    }
  }
  .slice-news {
    padding-top: 2rem;
    padding-bottom: 4rem;
    .news-row {
      margin-bottom: 2rem;
      h1 {
        margin-bottom: 2rem;
      }
      h2 a {
        color: black;
      }
    }
  }
  .slice-locations {
    padding: 4rem;
    h3 {
      margin-bottom: 2rem;
    }
    .embed {
      p,
      h1 {
        display: none;
      }
      img {
        margin-top: 1rem;
        width: 100%;
        max-width: 100%;
      }
    }
    .contact-col {
      border-left: 1px solid $primary;
      padding-left: 3.5rem;
      margin-left: 2rem;
      @include media-breakpoint-down(sm) {
        border-left-width: 0;
        padding-left: 0;
        margin-left: 0;
        margin-top: 4rem;
        }
    }
    .contact-disclaimer {
      padding-top: 2rem;
      font-size: .75rem;
    }
  }
}

.people-modal {
  padding: 2rem;
  margin: 2rem;
  border: 1px solid $primary;
  text-align: center;
  .close-button {
    float: right;
    padding: 2rem;
    margin-right: -3rem;
    margin-top: -3rem;
    font-size: 1.5rem;
  }
  .modal-headshot {
    clear: both;
    display: block;
    width: 13rem;
    border-radius: 50%;
    margin: 0 auto 2rem;
  }
  .area {
    font-weight: bold;
    color: $primary;
    margin-bottom: 2rem;
  }
  .email {
    a { color: inherit; }
    font-style: italic;
  }
  .quote {
    color: $primary;
    font-style: italic;
    margin-bottom: 2rem;
  }
  .perma {
    a { color: #ccc; }
  }
}