.global-nav {
  @extend .fixed-top;
  background-color: rgba(255, 255, 255, .7);
  button,
  a {
    display: block;
    color: #000;
  }
  .menu-items {
    @extend .list-unstyled;
    margin-bottom: 0;
    li {
      padding: .4rem 1rem;
      border-top: 1px solid #fff;
    }
    li:nth-child(3) {
      display: none;
    }
  }
  .menu-items.hide {
    display: none;
  }
  .mobile-header {
    @extend .d-flex;
    @extend .justify-content-between;
    @extend .align-content-center;
    padding: .4rem 1rem;
    img {
      height: 2rem;
      // display: block;
      vertical-align: middle;
    }
    font-size: 1.5rem;
  }
  @include media-breakpoint-up(md) {
    padding: 3rem 0 4rem;
    background-color: transparent;
    button,
    a {
      color: #fff;
    }
    .menu-items {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      li {
        border-top-width: 0;
        margin: 0 1rem;
        text-align: center;
      }
      li:nth-child(3) {
        display: block;
      }
      img {
        width: 9rem;
      }
    }
    .menu-items.hide {
      display: flex;
    }
    .mobile-header{
      display: none !important;
    }
  }
  @include media-breakpoint-up(lg) {
    .menu-items {
      li {
        margin: 0 2.5rem;
      }
    }
  }
}
.contact-modal {
  .phone {
    margin-top: 2rem;
    text-align: center;
    margin-bottom: 2rem;
  }
}

@include media-breakpoint-up(md) {
  .global-nav.light {
    button,
    a {
      color: #000;
    }
  }
  .global-nav.has-background {
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
  }
  .global-nav.light.has-background {
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
  }
}